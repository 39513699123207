import $ from "jquery";
import svg4everybody from "svg4everybody";
import "jquery-mousewheel";

svg4everybody();

//Imported  modules
import { dropdownHeader, dropdownFormGender } from "./modules/dropdown";
import { mobileMenu } from "./modules/mobile_menu";
import { togglePassword } from "./modules/password";
import { toggleVideo } from "./modules/video";
import { workoutTimer } from "./modules/timer-stopwatch";
import { workoutCountdownTimer } from "./modules/timer-countdown";
import { sliderSmall, sliderMedium, tutorialHandler } from "./modules/slider";
import { calculateTestingProgress } from "./modules/progress";
import { getMaxHeight } from "./modules/maxheight";
import { modalFunc } from "./modules/modal";
import { tooltipFunc, tooltipSheduleFunc } from "./modules/tooltip";
import { signUpValidate, validateDisableFrom } from "./modules/validation";
import { tableScroll, skillChartScroll, faqScroll } from "./modules/scroll";
import { internationalPhone } from "./modules/iti";
import {
    handleMonthDay,
    toggleWeekActive,
    visibilitySwitcher,
    toggleWhoopsImages,
    toggleSheduleTitle,
    handleIsFreezedDays,
    safariCalculateHeightVh
} from "./modules/css_actions";

tableScroll();
dropdownHeader();
mobileMenu();
togglePassword();
toggleVideo();
workoutTimer();
workoutCountdownTimer();
sliderSmall();
tutorialHandler();
sliderMedium();
getMaxHeight();
modalFunc();
tooltipFunc();
toggleWeekActive();
validateDisableFrom();
toggleSheduleTitle();
calculateTestingProgress();
safariCalculateHeightVh();

if ($(".page-content__inner").is("#sсhedule_main-page")) {
    handleIsFreezedDays();
    handleMonthDay();
    tooltipSheduleFunc();
}

if (
    $(".auth-form--signup").is("#signUpForm") ||
    $(".settings-form").is("#settingsForm")
) {
    internationalPhone();
    signUpValidate();
    dropdownFormGender();
}

if ($(".page-content__inner").is("#skillchart-page")) {
    skillChartScroll();
}

toggleWhoopsImages();

if ($(".page-content__inner").is(".page-content-faq")) {
    faqScroll();
}

if (
    $(".page-content__inner").is(".page-content-library") ||
    $(".page-content__inner").is(".page-content-faq")
) {
    visibilitySwitcher();
}

$("#terms").on("click", function() {
    $("#switcher-terms").attr("data-switcher-selected", "true");
    $("#switcher-conditions")
        .removeAttr("data-switcher-selected")
        .removeClass("selected");
    visibilitySwitcher();
});

$("#conditions").on("click", function() {
    $("#switcher-conditions").attr("data-switcher-selected", "true");
    $("#switcher-terms")
        .removeAttr("data-switcher-selected")
        .removeClass("selected");
    visibilitySwitcher();
});

// detect version of ios and device
var ver;
function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [
            "OS",
            parseInt(v[1], 10),
            parseInt(v[2], 10),
            parseInt(v[3] || 0, 10)
        ];
    } else if (/Mac/.test(navigator.platform)) {
        var v = navigator.appVersion.match(/(\d+).(\d+) Safari?/);
        return [parseInt(v[1], 10), parseInt(v[2], 10)];
    }
}
ver = iOSversion();
$(window).on("resize load", function() {
    const innerHeight = $(this).innerHeight();
    const innerWidth = $(this).innerWidth();
    //mobile safari
    if (ver !== undefined && innerWidth < innerHeight) {
        if (ver[0] === "OS") {
            $(".nav-outer-wrap").css("padding-bottom", "35%");
        }
    } else if (ver !== undefined && innerWidth > innerHeight) {
        if (ver[0] === "OS") {
            $(".nav-outer-wrap").css("padding-bottom", "10%");
        }
    }

    if (
        ver !== undefined &&
        innerWidth < innerHeight &&
        $(window).height() > 600
    ) {
        if (ver[0] === "OS") {
            $(".nav-outer-wrap").css("padding-bottom", "43%");
        }
    } else if (
        ver !== undefined &&
        innerWidth > innerHeight &&
        $(window).width() > 600
    ) {
        if (ver[0] === "OS") {
            $(".nav-outer-wrap").css("padding-bottom", "25%");
        }
    } 
    
   if (ver !== undefined && innerWidth < innerHeight && innerHeight > 710) {
      if (ver[0] === 'OS') {
        $(".nav-outer-wrap").css("padding-bottom", "25%");
        $(".nav-outer-wrap").css("height", "60%");
      }
  } else if (ver !== undefined && innerWidth > innerHeight && innerHeight > 710) {
    if (ver[0] === 'OS') {
      $(".nav-outer-wrap").css("padding-bottom", "0%");
      $(".nav-outer-wrap").css("height", "100%");
    }
  }
  if (ver !== undefined && innerWidth < innerHeight && innerHeight > 740) {
    if (ver[0] === 'OS') {
      $(".nav-outer-wrap").css("padding-bottom", "25%");
      $(".nav-outer-wrap").css("height", "63%");
    }
} else if (ver !== undefined && innerWidth > innerHeight && innerHeight > 740) {
  if (ver[0] === 'OS') {
    $(".nav-outer-wrap").css("padding-bottom", "0%");
    $(".nav-outer-wrap").css("height", "100%");
  }
}


    //mobile android (chrome) + tablet
    if (ver === undefined && innerWidth < innerHeight) {
        $(".nav-outer-wrap").css("padding-bottom", "25%");
        $(".page-sidebar").css("height", "100vh");
    } else if (ver === undefined && innerWidth > innerHeight) {
        $(".nav-outer-wrap").css("padding-bottom", "15px");
        $(".page-sidebar").css("height", "100vh");
    }
});
