import $ from "jquery";
import intlTelInput from "intl-tel-input";

export const internationalPhone = () => {
  const phoneInputWrapper = $("#form-control-phone");
  const phone_code = intlTelInput(document.querySelector("#phone_code"), {
    separateDialCode: true,
    initialCountry: "ua",
    preferredCountries: ["ua", "ru", "kz", "us"],
    utilsScript:
      "//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js",
  });

  signUpIti(phone_code);
  settingsIti(phone_code);
  // set width of drop-down list of internatinal codes with flags according to inputs wrapper
  $(window).on("resize load", function () {
    const elemWidth = phoneInputWrapper.width();
    $(".iti__country-list").css({ "max-width": elemWidth, width: elemWidth });
  });
};

const amendData = (src_array, name) => {
  const obj = src_array.find((el) => el.name === name);
  if (obj && obj.value === "Женский") obj.value = 2;
  if (obj && obj.value === "Мужской") obj.value = 1;

  return src_array;
};

const createError = (errorText, elem) => {
      if (errorText.length) {
        errorText.map((item) =>
          $("<span class='error'>").text(item).appendTo(elem)
        );
      }
};

const signUpIti = (phone_code) => {
  const signUpForm = $("#signUpForm");
  const submitFormHandler = (e) => {
    e.preventDefault();
    const countryCode = phone_code.getSelectedCountryData().dialCode;
    if($('.input-phone').val()) {
        const fullPhone = countryCode + $('.input-phone').val();
        $('#phone').val(fullPhone);
    } else {
        $('#phone').val('');
    }
    const formJson = signUpForm.serializeArray();
    amendData(formJson, "gender");
    let formData = {};
    signUpForm.find("input").each(function () {
      formData[this.name] = $(this).val();
    });
    const action = signUpForm.prop("action");
    $.post(action, formJson)
      .done(function () {
        window.location.href = '/email/verify';
      })
      .fail(function (error) {
        console.log(error.responseJSON);
        let errRes = error.responseJSON.errors;
        $('.error').remove();
        for (let key in errRes) {
          for (let formKey in formData) {
            if (key === formKey) {
                createError(
                errRes[key],
                $(`input[name=${key}]`).get(0).closest("div")
              );
            }
          }
        }
      });
  };
  signUpForm.on("submit", submitFormHandler);
};

const settingsIti = (phone_code) => {
  const settingsForm = $("#settingsForm");
  const submitFormHandler = (e) => {
    e.preventDefault();
     const countryCode = phone_code.getSelectedCountryData().dialCode;
    if($('.input-phone').val()) {
        const fullPhone = countryCode + $('.input-phone').val();
        $('#phone').val(fullPhone);
    } else {
        $('#phone').val('');
    }
   const formJson = settingsForm.serializeArray();
    amendData(formJson, "gender");
    let formData = {};
    settingsForm.find("input").each(function () {
      formData[this.name] = $(this).val();
    });
    const action = settingsForm.prop("action");
    $.post(action, formJson)
      .done(function () {
        window.location.reload();
      })
      .fail(function (error) {
        console.log(error.responseJSON);
        let errRes = error.responseJSON.errors;
        $('.error').remove();
        for (let key in errRes) {
          for (let formKey in formData) {
            if (key === formKey) {
                createError(
                errRes[key],
                $(`input[name=${key}]`).get(0).closest(".form-group--settings")
              );
            }
          }
        }
      });
  };
  settingsForm.on("submit", submitFormHandler);
};
