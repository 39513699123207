import $ from 'jquery';

export const calculateTestingProgress = () => {
  var $progressPointsContainer = $('.progress-points');
  var $progressPointsCount = $progressPointsContainer.find('.progress-point')
    .length;
  //  get position number active element
  var $progressPointActiveInd = $progressPointsContainer
    .find('.progress-point')
    .index($('.active'));
  // width for one section (ex. for 3 section - width one = 33.333% //
  var widthOneSection = 100 / ($progressPointsCount - 1);
  // start width value //
  var $progressPointLineWidth = 0;
  if ($progressPointActiveInd === $progressPointsCount - 1) {
    $progressPointLineWidth = 100;
  } else {
    var i = 0;
    while (i < $progressPointActiveInd) {
      $progressPointLineWidth += widthOneSection;
      i++;
    }
  }
  $progressPointsContainer
    .find('.progress-points__line')
    .css('width', `${$progressPointLineWidth}%`);
};
