import $ from 'jquery';

// calculate max height of the video to align content (content will have scroll according to this)
export const getMaxHeight = () => {
  $(window).on('load resize', function () {
    getMaxHeightTaskItem($('.task-item__info_inner'), '.testing-panel');
    getMaxHeightTaskItem($('.task-item__info_inner'), '.task-item');
  });

  function getMaxHeightTaskItem(elToHeight, classParentItem) {
    if ($(window).width() > 1200) {
      elToHeight.css(
        'max-height',
        `${elToHeight
          .closest(classParentItem)
          .find('.video-wrapper')
          .height()}px`
      );
    } else {
      elToHeight.css('max-height', 'initial');
    }
  }
};
