import $ from 'jquery';

// toggle pass showing
export function togglePassword() {
  const btnVisibleHiddenPswrd = '.dr-btn_password';
  const  parent = '.form-control__group-btn';
  const passBtns = $('.dr-btn_password').children();

  $(btnVisibleHiddenPswrd).on('click', function () {
    const inputPswrd = $(this).closest(parent).find('input');
    const typeInput =
    inputPswrd.attr('type') === 'password' ? 'text' : 'password';
    inputPswrd.attr('type', typeInput);
    $(this).toggleClass('is-active');
    passBtns.on('click', function() {
      if (typeInput === 'text') {
        $(this).removeClass('is-active');
      } else {
        $(this).addClass('is-active');
      }
    })
  });
}
