import $ from 'jquery';

export const tooltipFunc = () => {
  $('.tooltip-custom').on('click', function () {
    let txtTooltip = $(this).data('tooltip-c-content');
    let htmlTooltipNotice = `<span class="tooltip-custom__content">${txtTooltip}</span>`;
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      $(this).find('.tooltip-custom__content').remove();
    } else {
      $(this).append(htmlTooltipNotice);
      $(this).addClass('is-active');
    }
  });
};

export const tooltipSheduleFunc = () => {
  const tooltipSidebarBtn = $('.shedule-tooltip-wrap');
  const tooltipSidebarBtnLarge = $('.shedule-tooltip-wrap-larger');
  let tooltip =  $('#shedule-tooltip');
  const calculateRect = () => {
    let rect = tooltipSidebarBtn[0].getBoundingClientRect();
    tooltip.css({
      top: rect.top - 75,
      left: rect.left - 47,
    });
    if($(window).width() > 767) {
      tooltip.css({
        top: rect.top - 90,
        left: rect.left - 47,
      });
    }
  }
  const calculateLargerRect = () => {
    let rect = tooltipSidebarBtnLarge[0].getBoundingClientRect();
    tooltip.css({
      top: rect.top - 95,
      left: rect.left - 55,
    });
    if($(window).width() > 767) {
      tooltip.css({
        top: rect.top - 110,
        left: rect.left - 55,
      });
    }
  }
  const removeElems = () => {
    tooltipSidebarBtn.removeClass('is-active');
    tooltip.addClass('hidden').removeClass('is-active');
  }
  tooltipSidebarBtn.on('click', function () {
    calculateRect();
    calculateLargerRect();
      if (tooltipSidebarBtn.hasClass('is-active')) {
      removeElems();
    } else {
      tooltip.removeClass('hidden').addClass('is-active');
      tooltipSidebarBtn.addClass('is-active');
    }
  })
  tooltip.on('click', function () {
    if (tooltip.hasClass('is-active')) {
      removeElems();
    } 
  })
  $(window).on('click', function (e) {
  if(!tooltipSidebarBtn.is(e.target) && tooltipSidebarBtn.has(e.target).length === 0) {
    removeElems();
    }
  })

  $(window).on('resize load', function () {
    calculateRect();
    calculateLargerRect();
  });
};
