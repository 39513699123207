import $ from "jquery";
import SimpleBar from "simplebar";

export const tableScroll = () => {
    const scrollBtn = $("#table-scrollBtn");
    scrollBtn.on("click", function(event) {
        event.preventDefault();
        var id = $(this).attr("href"),
            top = $(id).offset().top;
        $("body,html").animate({ scrollTop: top }, 1000);
    });
};

export const faqScroll = () => {
    let elem = $(".faq__header-wrap")[0];
    $(window).on("resize load", function() {
        new SimpleBar(elem, {
            autoHide: false,
            direction: "ltr"
        });
        elem.onwheel = event => {
            event.preventDefault();
            let elementToScroll = elem.querySelector(
                ".simplebar-content-wrapper"
            );
            clearTimeout(elementToScroll.timer);
            elementToScroll.timer = setTimeout(() => {
                elementToScroll.scrollTo({
                    left:
                        event.deltaY > 0
                            ? elementToScroll.scrollLeft + 60
                            : elementToScroll.scrollLeft - 60,
                    behavior: "smooth"
                });
            }, 20);
        };
    });
};

export const skillChartScroll = () => {
    let elem = $(".skillcharts-grid__body");
    console.log("oloo");
    $(window).on("resize load", function() {
        elem.each(function(i, item) {
            new SimpleBar(item, {
                autoHide: false,
                direction: "ltr"
            });
            if ($(window).width() < 520) {
                item.onwheel = event => {
                    event.preventDefault();
                    let elementToScroll = item.querySelector(
                        ".simplebar-content-wrapper"
                    );

                    clearTimeout(elementToScroll.timer);
                    elementToScroll.timer = setTimeout(() => {
                        elementToScroll.scrollTo({
                            left:
                                event.deltaY > 0
                                    ? elementToScroll.scrollLeft + 60
                                    : elementToScroll.scrollLeft - 60,
                            behavior: "smooth"
                        });
                    }, 20);
                };
            } else {
                return null;
            }
        });
    });
};
