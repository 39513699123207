import $ from "jquery";

// toggle mobile menu visibility
export function mobileMenu() {
  $(".dr-btn__mobile-menu").on("click", function (e) {
    e.preventDefault();
    $(this)
      .closest(".page")
      .find(".page-sidebar__wrapper")
      .addClass("is-active");
    $("body, html").addClass("overflow-hidden");
  });
  $(".dr-btn__mobile-menu_close").on("click", function (e) {
    e.preventDefault();
    $(this)
      .closest(".page")
      .find(".page-sidebar__wrapper")
      .removeClass("is-active");
    $("body, html").removeClass("overflow-hidden");
  });

  $(window).on("resize load", function () {
    const innerHeight = $(this).innerHeight();
    const innerWidth = $(this).innerWidth();
    if (innerWidth > innerHeight && $(this).width() > 991) {
      $(".page-sidebar__wrapper").removeClass("is-active");
      $("body, html").removeClass("overflow-hidden");
      $(".page-sidebar__overlay").css("display", "none");
    } else {
      $(".page-sidebar__overlay").css("display", "block");
    }
  });

  // close sidebar after click outside
  if ($(".page-sidebar__wrapper")) {
    $(window).on("click", function (e) {
      if (
        $(e.target).hasClass("page-sidebar") ||
        $(e.target).hasClass("page-sidebar__overlay")
      ) {
        $(".page-sidebar__wrapper").removeClass("is-active");
        $("body, html").removeClass("overflow-hidden");
      }
    });
  }
}
