import $ from 'jquery';
import Timer from 'easytimer.js';

export const workoutTimer = () => {
  const timer = new Timer();
  const start = $('#timer-task_start');
  const stop = $('#timer-task_stop');
  const repeat = $('#timer-task_repeat');
  const reset = $('#timer-task_reset');
  const save = $('#timer-task_save');
  const controls = $('#timer-actions');
  const timerValue = $('#timer-task_value');
  const timerText = $('#timer-task_text');
  const timerInput = $('#timer_input');
  const active = 'is-active';

  start.on('click', function () {
    timer.start();
    start.removeClass(active);
    stop.addClass(active);
  });

  stop.on('click', function () {
    timer.stop();
    setTimerInputValue();
    stop.removeClass(active);
    controls.addClass(active);
    timerText.removeClass('hidden');
  });

  repeat.on('click', function () {
    timer.reset();
    timer.stop();
    setTimerInputValue();
    timerText.addClass('hidden');
    controls.removeClass(active);
    start.addClass(active);
  });

  save.on('click', function () {
    controls.removeClass(active);
    reset.addClass(active);
  });

  reset.on('click', function () {
    timer.reset();
    timer.stop();
    setTimerInputValue();
    timerText.addClass('hidden');
    reset.removeClass(active);
    start.addClass(active);
  });

  timer.addEventListener('secondsUpdated', function (e) {
    timerValue.html(timer.getTimeValues().toString());
  });

  timer.addEventListener('started', function (e) {
    timerValue.html(timer.getTimeValues().toString());
  });

  timer.addEventListener('reset', function (e) {
    timerValue.html(timer.getTimeValues().toString());
  });

  function setTimerInputValue(){
    var timerValueInSeconds = timerValue.html().split(':').reverse().reduce((prev, curr, i) => prev + curr*Math.pow(60, i), 0);
    timerInput.val(timerValueInSeconds);
  }
};
