import $ from 'jquery';
import Timer from 'easytimer.js';

export const modalFunc = () => {
  let modalBtnClose = '.dr-modal__close';
  let activeClass = 'is-active';

  // open modal after btn click
  $('*[data-modal]').on('click', function (e) {
    e.preventDefault();
    drModalShow($(this));
  });
  
  // close modal after btn click
  $('body').on('click',modalBtnClose, function () {
    drModalHide($(this));
  });
  function drModalHide(context) {
    $(context).closest('.dr-modal').removeClass(activeClass);
    $('#page-sidebar__wrapper').css('z-index', 2)
    $('html').removeClass('o-hidden');
  }
  function drModalShow(currentTarget, overlayStatus = true) {
    let btnModalId = currentTarget.data('modal');
    let modal = `.dr-modal[id="${btnModalId}"]`;
    if (btnModalId) {
      $(modal).addClass(activeClass);
    }
    if($(modal).hasClass(activeClass)) {
      $('#page-sidebar__wrapper').css('z-index', 1);
    }
    $(modal)
      .find('.dr-modal__overlay')
      .eq(0)
      .css('opacity', overlayStatus ? '1' : '0');
    $('html').addClass('o-hidden');
  }
  $('.modal-cancel, #btn_deleteAccount').on('click', function () {
    drModalHide($(this));
  });
  // close modal after click outside
  $('.dr-modal__overlay').on('click', function () {
    drModalHide($(this));
  });

  const timer = new Timer();
  const timerValueFromBack = $('#futureTraining').data('time');
  const postponeBtn = $('#postponeBtn');
  const dumpState = { timerValueFromBack };

  const timerUpdate = function () {
    $('#futureTraining').html(
      timer.getTimeValues().toString().split(':').join(' : ')
    );
  };

  const countdownTimer = () => {
    const { timerValue, timerValueFromBack } = dumpState;
    const checkedTimer = timerValue || timerValueFromBack;
    if (timer.isRunning()) {
      timer.removeEventListener('secondsUpdated', timerUpdate);
      timer.stop();
    }
    timer.start({ countdown: true, startValues: { seconds: checkedTimer } });
    timer.addEventListener('secondsUpdated', timerUpdate);
  };

  countdownTimer();

  postponeBtn.on('click', function () {
    drModalHide($(this));
    const hoursFromInput = $('#postponeInput').val().substr(0, 2);
    const minutesFromInput = $('#postponeInput').val().substr(4, 5);
    const selectedSeconds = +hoursFromInput * 3600 + +minutesFromInput * 60;

    const date = new Date();
    const dateSeconds =
      date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();

    if (selectedSeconds > dateSeconds) {
      const timerValue = selectedSeconds - dateSeconds;
      dumpState.timerValue = timerValue;
      countdownTimer();
    } else if (
      dateSeconds === selectedSeconds ||
      selectedSeconds < dateSeconds
    ) {
      dumpState.timerValue = 0;
      countdownTimer();
    }
  });
};
