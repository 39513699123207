import $ from "jquery";
import "jquery-validation";

export const signUpValidate = () => {
  const form = $("#signUpForm");
  const submitBtn = $("#submitSignup");

  form.validate({
    rules: {
      name: {
        required: true,
        minlength: 2,
      },
      gender: "required",
      email: {
        required: true,
        email: true,
      },
      phone_number: {
        required: false,
      },
      phone_code: {
        required: false,
      },
      password: {
        required: true,
        minlength: 6,
      },
      confirm_password: {
        required: true,
        minlength: 6,
        equalTo: "#password",
      },
      agreement: {
        required: false,
      },
    },
    messages: {
      name: {
        required: "Поле должно быть заполнено",
        minlength: "Имя должно состоять как минимум из двух букв",
      },
      gender: {
        required: "Выберите один из вариантов",
      },
      email: {
        required: "Поле должно быть заполнено",
        email: "Неверный формат e-mail",
      },
      password: {
        required: "Поле должно быть заполнено",
        minlength: "Минимальное кол-во символов 6",
      },
      confirm_password: {
        minlength: 6,
        required: "Поле должно быть заполнено",
        minlength: "Минимальное кол-во символов 6",
        equalTo: "Пароли должны совпадать",
      },
    },
  });

  // checkbox validation
  $("#submitSignup").on("click", function () {
    if (!$(".signup-terms input[type=checkbox]").is(":checked")) {
      $(".checkbox-box--signup").css("border-color", "red");
    }
  });
};

// disable submit btn untill all inputs are filled and valid
export const validateDisableFrom = () => {
  checkInputFilled();
  $("#signUpForm .form-control__validate").on("keyup", checkInputFilled);
  $("#select-gender .gender-text").on("click", checkInputFilled);
  $("#signupCheckbox").on("click", checkInputFilled);
};
let genderInput = $("#signUpForm #gender");

export const checkInputFilled = () => {
  var inputsWithValues = 0;
  const allInputs = $("#signUpForm .form-control__validate");

  $("#select-gender .gender-text").on("click", function (e) {
    let val = $(e.target).text();
    genderInput.val(val);
  });

  allInputs.each(function (e) {
    if ($(this).val()) {
      inputsWithValues += 1;
    }
  });

  if (
    inputsWithValues === allInputs.length &&
    $("#signupCheckbox").is(":checked")
  ) {
    $("#submitSignup").prop("disabled", false);
    $("#signupCheckbox").val(1);
  } else {
    $("#submitSignup").prop("disabled", true);
    $("#signupCheckbox").val(0);
  }
};
