import $ from "jquery";

// mark current day on page shedule
export const handleMonthDay = () => {
  $(".month-day").each(function () {
    if ($(this).text() === "11") {
      $(this).closest(".dr-t-row-hovered .dr-t-cell").addClass("current-day");
    }
  });
};

// marks days as freezed on page schedule
export const handleIsFreezedDays = () => {
  $(".month-day").each(function () {
    if ($(this).text() === "12") {
      $(this)
        .closest(".dr-t-row-hovered .dr-t-cell")
        .addClass("is-freezed")
        .addClass("is-freezed--first");
    }
    if ($(this).text() === "13") {
      $(this)
        .closest(".dr-t-row-hovered .dr-t-cell")
        .addClass("is-freezed is-freezed--last");
    }
  });
};

// mark active weeks on pages shedule edit
export const toggleWeekActive = () => {
  var $weekRow = $(".week-row");
  if (!$weekRow.closest(".week-list").hasClass("week-list_notjs")) {
    $weekRow.on("click", function () {
      if (!$(this).hasClass("is-active")) {
        $weekRow.each(function () {
          $(this).removeClass("is-active");
        });
        $(this).addClass("is-active");
      }
    });
  }
};

// toggle image for pages whoopd 404 / 419 from mobile img to desktop img
export const toggleWhoopsImages = () => {
  $(window).on("resize load", function () {
    let $whoopsImgDesk = $(".whoops__image");
    let $whoopsImgMob = $(".whoops__image-mobile");
    if (window.matchMedia(`(max-width: 767px)`).matches) {
      $whoopsImgDesk.addClass(`hidden`);
      $whoopsImgMob.removeClass(`hidden`);
    } else {
      $whoopsImgDesk.removeClass(`hidden`);
      $whoopsImgMob.addClass(`hidden`);
    }
  });
};

export const toggleSheduleTitle = () => {
  $(window).on("resize load", function () {
    if ($(this).width() > 767) {
      $(".shedule-title").each(function () {
        $(this).text($(this).data("shedule-title-full"));
      });
    } else {
      $(".shedule-title").each(function () {
        $(this).text($(this).data("shedule-title-short"));
      });
    }
  });
};

// specially for safari. Replaces height: 100vh (currently is used for sidebar)
export const safariCalculateHeightVh = () => {
  $(window).on("resize load", function () {
    const innerHeight = $(this).innerHeight();
    const innerWidth = $(this).innerWidth();
    if (innerWidth > innerHeight) {
      $(".page-sidebar").css("height", `100%`);
    } else {
      $(".page-sidebar").css("height", `100%`);
    }
  });
};

// toggle content visibility according to which header was clicked (currently used on faq, terms&conditions, library pages)
export const visibilitySwitcher = () => {
  [].forEach.call(document.body.querySelectorAll("[data-switcher]"), (a) => {
    let b = a.querySelectorAll("[data-switcher-name]"),
      c = a.querySelectorAll("[data-switcher-show]");
    [].forEach.call(c, (d) => {
      if (d.dataset.switcherSelected != undefined) {
        d.classList.add("selected");
        [].filter.call(b, (a) =>
          a.dataset.switcherName == d.dataset.switcherShow
            ? a.classList.add("selected")
            : ""
        );
      }
      d.addEventListener(
        "click",
        () => {
          [].forEach.call(c, (a) =>
            a != d
              ? a.classList.remove("selected")
              : a.classList.add("selected")
          );
          [].forEach.call(b, (a) =>
            a.dataset.switcherName != d.dataset.switcherShow
              ? a.classList.remove("selected")
              : a.classList.add("selected")
          );
        },
        true
      );
    });
  });
};
