import $ from 'jquery';
import Timer from 'easytimer.js';

export const workoutCountdownTimer = () => {
  const timer = new Timer();
  const start = $('.timer-task--tasks .timer-task__btn_start');
  const repeat = $('.timer-task--tasks .timer-task__btn_repeat');
  const btnController = $('.timer-task__controler .dr-btn');
  const container = $('.timer-task__controls--tasks').children();
  let wrapperID;

  start.on('click', (event) => {
    event.preventDefault();
    let seconds = $(event.target).attr('data-begin-time');

    wrapperID = event.target.parentNode.parentNode.id;
    timer.start({ countdown: true, startValues: { seconds: Number.parseInt(seconds) } });
    [...container].forEach((elem) => {
      if (elem.id && elem.id !== event.target.id) {
        let targetID = '#' + elem.id;
        $(targetID).prop('disabled', true);
      }
    });
  });

  repeat.on('click', function (e) {
    e.preventDefault();
    timer.reset();
    timer.stop();
    start.prop('disabled', false);
  });

  timer.addEventListener('secondsUpdated', function (e) {
    $(`#${wrapperID} .timer-task__value`).html(
      timer.getTimeValues().toString()
    );
    if (timer.getTimeValues().toString() === '00:00:00') {
      start.prop('disabled', false);
      start.addClass('is-active');
      repeat.removeClass('is-active');
    }
  });

  timer.addEventListener('reset', function (e) {
    $(`#${wrapperID} .timer-task__value`).html(
      timer.getTimeValues().toString()
    );
  });

  btnController.on('click', function (e) {
    e.preventDefault();
    $(this).removeClass('is-active');
    if (!$(this).hasClass('timer-task__btn_repeat')) {
      $(this).next().addClass('is-active');
    } else {
      $(this)
        .closest('.timer-task__controler')
        .find('.dr-btn')
        .eq(0)
        .addClass('is-active');
    }
  });
};
