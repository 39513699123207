import $ from 'jquery';  

// toggle video showing
export function toggleVideo () {
  $('body').on('click', '.video-btn_play', function() {
    var videoWrapper = $(this).closest('.video-wrapper');
    videoWrapper.find('.video-preview').addClass('video-preview_hidden');
    videoWrapper.find('video').eq(0).trigger('play');
  });
	
}