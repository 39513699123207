import $ from 'jquery';
import 'slick-carousel';

// slider medium
export const sliderMedium = () => {
  let nextArr = '<span class="slider-button-next"></span>';
  let prevArr = '<span class="slider-button-prev"></span>';

  $('.slider--testing_one').on(
    'init reInit afterChange',
    function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      let i = (currentSlide ? currentSlide : 0) + 1;
      if (i === slick.slideCount) {
        $('.slider-button-next').addClass(
          ` slider-button-close dr-modal__close`
        );
      } else {
        $('.slider-button-next').removeClass(
          ` slider-button-close dr-modal__close`
        );
      }
      if (i === 1) {
        $('.slider-button-prev').addClass(` hidden`);
      } else {
        $('.slider-button-prev').removeClass(` hidden`);
      }
    }
  );

  const setAdaptiveHeightXs = () => {
    if (
      $('.slider--testing_one').hasClass('adaptive-modal') ||
      $('.slider--testing_one').hasClass('adaptive-modal-sm')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setAdaptiveHeightSm = () => {
    if ($('.slider--testing_one').hasClass('adaptive-modal-sm')) {
      return true;
    } else {
      return false;
    }
  };

  $('.slider--testing_one').slick({
    slidesToShow: 1,
    dots: true,
    infinite: false,
    arrows: true,
    nextArrow: nextArr,
    prevArrow: prevArr,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          adaptiveHeight: setAdaptiveHeightSm(),
        },
      },
      {
        breakpoint: 575,
        settings: {
          adaptiveHeight: setAdaptiveHeightXs(),
        },
      },
    ],
  });
};

// slider small
export const sliderSmall = () => {
  let nextArrSm = '<span class="slider-button-next-sm"></span>';
  let prevArrSm = '<span class="slider-button-prev-sm"></span>';

  $('.slider--testing_one--sm').on(
    'init reInit afterChange',
    function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      let i = (currentSlide ? currentSlide : 0) + 1;
      if (i === slick.slideCount) {
        $('.slider-button-next-sm').addClass(
          ` slider-button-close dr-modal__close`
        );
      } else {
        $('.slider-button-next-sm').removeClass(
          ` slider-button-close dr-modal__close`
        );
      }
      if (i === 1) {
        $('.slider-button-prev-sm').addClass(` hidden`);
      } else {
        $('.slider-button-prev-sm').removeClass(` hidden`);
      }
    }
  );

  $('.slider--testing_one--sm').slick({
    slidesToShow: 1,
    dots: true,
    infinite: false,
    arrows: true,
    nextArrow: nextArrSm,
    prevArrow: prevArrSm,
  });
};

export const tutorialHandler = () => {
    $('body').on('click','.slider-button-close', function(){
        let slider = $(this).closest('.dr-modal-wrapper');
        let name = slider.data('tutorial-slug');
        console.log('Tutorial acknowledge:'+name);

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            type: "POST",
            url: 'tutorial-acknowledge',
            data: {name: name},
            dataType: 'json',
            success: function (msg) {
                console.log(msg);
            },
            error: function () {
                console.log('Error updating tutorial acknowledge');
            }
        });
    });

};
