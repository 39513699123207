import $ from 'jquery';

// dropdown
export function dropdownHeader() {
  $('.dropdown-header').on('click', function (e) {
    e.preventDefault();
    $(this).closest('.dropdown').toggleClass('is-active');
    $(this).toggleClass('is-active');
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.dropdown').length) {
      $('.dropdown').removeClass('is-active');
    }
  });
}

export function dropdownFormGender() {
  const genderInput = $('#gender');
  const dropdownWrapper = $('#dropdown-gender');
  const select = $('#select-gender');

  genderInput.on('click', function (e) {
    e.preventDefault();
    $(this).closest(dropdownWrapper).toggleClass('is-active');
    $(this).toggleClass('is-active');
  });
  select.on('click', function (e) {
    genderInput.val($(e.target).text());
    dropdownWrapper.removeClass('is-active');
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest(dropdownWrapper).length) {
      dropdownWrapper.removeClass('is-active');
    }
  });
}
